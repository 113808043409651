export function WooWatermark_isset( arr, var_args ) {
  for (var i=1, k=arguments.length; i<k; ++i) {
    if (!arr || !arr.hasOwnProperty(arguments[i]) )
      return false
    arr = arr[arguments[i]];
  }
  return true
}

export function WooWatermark_uniqid()
{
  let array = new Uint32Array(8)
  window.crypto.getRandomValues(array)
  let str = ''
  for (let i = 0; i < array.length; i++) {
    str += (i < 2 || i > 5 ? '' : '-') + array[i].toString(16).slice(-4)
  }
  return str
}