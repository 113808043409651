import { WooWatermark_isset as isset } from "./Utils";

export default function ImageWithWatermark( image_url='', settings={}, multiple=true ) 
{  
  return new Promise((resolve, reject) => {
    const cfg = {};
    cfg.grid_size = settings['grid-size'];
    let logo = isset(settings, 'logo', 'url') ? settings['logo'].url : WooWatermark.logo_img;

    const body = document.getElementsByTagName("body")[0];
    let exists = false;
    let imageCanvas = false;
    let watermarkCanvas = false;

    if( multiple == false ){
      if( document.querySelector( `.${WooWatermark.alias}imageCanvas` ) ){
        exists = true;
      }
    }
    
    // build canvas for the main image and append to the body
    if( exists ) { 
      imageCanvas = document.querySelector( `.${WooWatermark.alias}imageCanvas` );
    }else{
      imageCanvas = document.createElement('canvas');
      imageCanvas.classList.add( `${WooWatermark.alias}imageCanvas` );
      body.appendChild( imageCanvas );
    }

    const imageContext = imageCanvas.getContext("2d");

    // build canvas for the watermark and append to the body
    if( exists ) { 
      watermarkCanvas = document.querySelector( `.${WooWatermark.alias}watermarkCanvas` );
    }else{
      watermarkCanvas = document.createElement('canvas');
      watermarkCanvas.classList.add( `${WooWatermark.alias}watermarkCanvas` );
      body.appendChild( watermarkCanvas );
    }

    const watermarkContext = watermarkCanvas.getContext("2d"); 

    const setImage = ( callback ) => {
      var img = new Image();
      img.onload = function() {
        cfg.img = {
          'src': image_url,
          'width': this.width,
          'height': this.height,
        };

        if( typeof(callback) == 'function' ){
          callback( this );
        }
      }
      img.src = image_url;
    }

    const preloadLogoImage = ( callback ) => {
      var logoImg = new Image();
      logoImg.onload = function() {
        cfg.logoImg = {
          'src': logo,
          'width': this.width,
          'height': this.height,
        };

        if( typeof(callback) == 'function' ){
          callback( this );
        }
      }
      logoImg.src = logo;
    }

    const debugWatermark = () => {
      watermarkContext.strokeStyle = "#000";
      watermarkContext.beginPath();
      watermarkContext.rect( 0, 0, cfg.grid_size, cfg.grid_size );
      watermarkContext.stroke();
    }

    const drawLines = ( logoImg ) => {

      watermarkContext.shadowBlur = settings['line-blur'];
      watermarkContext.shadowColor = settings['line-blur-color'];

      // lines 
      const goBackSize = logoImg.height / 2 + 10 /* extra padding */;

      const line_width = settings['line-width'];

      watermarkContext.globalAlpha = settings['line-opacity'];
      watermarkContext.setLineDash([line_width, line_width + 4]);
      watermarkContext.beginPath();
      watermarkContext.strokeStyle = settings['line-color'];

      // line top left
      watermarkContext.moveTo( line_width, line_width );
      watermarkContext.lineTo( cfg.grid_size / 2 - goBackSize, cfg.grid_size / 2 - goBackSize  );

      // line top right
      watermarkContext.moveTo( cfg.grid_size - line_width, line_width );
      watermarkContext.lineTo( cfg.grid_size / 2 + goBackSize, cfg.grid_size / 2 - goBackSize  );

      // line bottom left
      watermarkContext.moveTo( line_width, cfg.grid_size - line_width );
      watermarkContext.lineTo( cfg.grid_size / 2 - goBackSize, cfg.grid_size / 2 + goBackSize  );

      // line bottom right
      watermarkContext.moveTo( cfg.grid_size + line_width, cfg.grid_size + line_width );
      watermarkContext.lineTo( cfg.grid_size / 2 + goBackSize, cfg.grid_size / 2 + goBackSize  );

      watermarkContext.stroke(); 
    }

    const drawLogo = ( theLogoImage ) => {
      watermarkContext.shadowBlur = settings['logo-blur'];
      watermarkContext.shadowColor = settings['logo-blur-color'];

      watermarkContext.globalAlpha = settings['logo-opacity'];

      // draw the logo
      watermarkContext.drawImage( 
        theLogoImage, 
        ( cfg.grid_size - theLogoImage.width ) / 2, 
        ( cfg.grid_size - theLogoImage.height ) / 2 
      );

      watermarkContext.shadowBlur = settings['line-blur'];
      watermarkContext.shadowColor = settings['line-blur-color'];
    }

    const drawText = () => {
      if( !isset(cfg, 'textSize', 'height') ) measureText();

      watermarkContext.font = `${settings['text-size']}px Arial`;
      watermarkContext.textAlign = "center";
      watermarkContext.fillStyle = settings['text-color'];
      watermarkContext.fillText( settings['custom-text'], cfg.grid_size / 2, cfg.grid_size / 2 + cfg.textSize.height / 2 - 3 /* extra adjust !!! */ );
    }

    const measureText = () => {
      var d = document.createElement("span");
      d.textContent = settings['custom-text'];
      d.style.cssText = `font-family: Arial !important; color: red !important; font-size: ${settings['text-size']}px !important;`
      document.body.appendChild(d);
      
      cfg.textSize = {
        width: d.offsetWidth,
        height: d.offsetHeight,
      };

      document.body.removeChild(d);
    }

    setImage(( theImage ) => {

      // draw main image
      imageCanvas.width = theImage.width;
      imageCanvas.height = theImage.height;
      imageContext.drawImage( theImage, 0, 0 );

      // draw watermark context
      watermarkCanvas.width = settings['grid-size'];
      watermarkCanvas.height = settings['grid-size'];

      
      preloadLogoImage( ( logoImg ) => {

        if( settings['type'] == 'logo' ){
          
          cfg.grid_size = logoImg.width > logoImg.height ? logoImg.width : logoImg.height;

          watermarkCanvas.width = cfg.grid_size;
          watermarkCanvas.height = cfg.grid_size;
        }

        if( settings['type'] == 'text' ){
          measureText();

          cfg.grid_size = cfg.textSize.height > cfg.textSize.width ? cfg.textSize.height : cfg.textSize.width;
          
          watermarkCanvas.width = cfg.grid_size;
          watermarkCanvas.height = cfg.grid_size;
        }

        if( settings['type'] == 'logo-stripped-lines' || settings['type'] == 'logo' ){
          drawLogo( logoImg );
        }

        if( settings['type'] == 'text' || settings['type'] == 'text-stripped-lines' ){
          drawText();
        }

        if( settings['type'] == 'text-stripped-lines' || settings['type'] == 'logo-stripped-lines' ){
          drawLines( logoImg );

          // draw logo canvas multiple times
          for (var i = Math.ceil(cfg.img.height / cfg.grid_size) - 1; i >= 0; i--) {
            for (var cc = Math.ceil(cfg.img.width / cfg.grid_size) - 1; cc >= 0; cc--) {
              imageContext.drawImage(watermarkCanvas, cc * cfg.grid_size, i * cfg.grid_size);
            }
          }
        }

        if( settings['type'] == 'text' || settings['type'] == 'logo' ){

          let xPos = 0;
          let yPos = 0;
          const align = settings['align'];

          if( align == 'top-left' ){
            xPos = settings['margin-left'];
            yPos = settings['margin-top'];
          }
          else if( align == 'top-right' ){
            xPos = cfg.img.width - cfg.grid_size - settings['margin-right'];
            yPos = settings['margin-top'];
          }
          else if( align == 'center' ){
            xPos = cfg.img.width / 2 - cfg.grid_size / 2;
            yPos = cfg.img.height / 2 - cfg.grid_size / 2;
          }
          else if( align == 'bottom-left' ){
            xPos = settings['margin-left'];
            yPos = cfg.img.height - cfg.grid_size - settings['margin-bottom'];
          }
          else if( align == 'bottom-right' ){
            xPos = cfg.img.width - cfg.grid_size - settings['margin-right'];
            yPos = cfg.img.height - cfg.grid_size - settings['margin-bottom'];
          }

          imageContext.drawImage( watermarkCanvas, xPos, yPos );
        }
        

        resolve( imageCanvas );
      })
    })
  })
}