const { Component, render } = wp.element;
import ImageWithWatermark from './helpers/ImageWithWatermark'
import axios from "axios";


export default function Main({ images }) {

  const $ = jQuery;

  const list = Object.assign({}, images);

  const saveImage = ( url, imgId, mediaData, callback ) => {
    axios({
      method: 'post',
      url: WooWatermark.ajax_url,
      params: {
        'section'   : 'App',
        'act'       : 'save_image',
        'url'       : url,
        'imgId'     : imgId
      },
      data: mediaData
    })
    .then((response) => {
      if( typeof(callback) == 'function' ){
        callback( response )
      }
    })
  }

  const watermarkImage = () => {
    if( Object.keys(list).length ){
      const first_item = Object.keys(list)[0];

      const imageWatermarked = ImageWithWatermark( first_item, WooWatermark.settings, false );
      imageWatermarked.then(function( imageCanvas ){
          const newImageDataUrl = imageCanvas.toDataURL("image/jpeg");

          const mediaId = list[first_item];
          saveImage( first_item, mediaId, newImageDataUrl, () => {
            delete list[first_item];
            watermarkImage();
          } )
      })
    }else{
      console.log( 'WooWatermarkFrontend done!' );
    }
  }

  watermarkImage();

  return (
    <div></div>
  );
}

const plugin_wrapper = document.getElementById( `WooWatermarkFrontend` );
wp.domReady( function() {
  console.log( plugin_wrapper.value );
  if( plugin_wrapper ) render( <Main images={ JSON.parse( plugin_wrapper.value ) } />, plugin_wrapper );
} );